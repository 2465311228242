@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300|Playfair+Display:400,400i,700,700i&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.previewImage_container{
  text-align: center;
  display : block;
  margin : auto;
}

.previewImage {
  border:thin solid black;
  max-width:300px;
}

.title-preview {
  font-family: 'Playfair Display', 'sans-serif';
  float: left;
  font-size: 1.3em;
  text-align: left;
  border: 1px solid grey;
  padding: 4px;
  width:300px;
}

.fp-modellnavn {
  display: inline-block;
  font-weight: 700;
}


.fp-publikasjon{
  display: inline-block;
  font-weight: 400;
}

.fp-fotograf{
  display: inline-block;
  font-weight: 400;
  font-style: italic;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  overflow: scroll;
}

.newModelDiv {
  float:left;
  margin:10px; text-align:center;
}

.newImg {
object-fit: cover;
  height: 200px;
  width: 200px;
  border: 1px solid black;
  display:inline-block;
}

.dropzone {
  width:400px;
  border: 4px dashed black;
  height:200px;
}


.col-form-label {
  text-align:left;
}

.testimage1 {
  background: #fefefe;
  border: 1px solid #000000;
  width: 130px;
}

.testimage2 {
  background: #fefefe;
  border: 5px solid #ff0000;
  width: 130px;
}


.modelimg {
  position:relative;
  width: 100%;
  height: auto;
  min-height: auto;

  padding: 0px;
  margin: 0px;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

body {
  background-color: #f1f2f3;
}

.modellcelle {
  text-align:left;
  text-wrap: none;
}

.modellcelle2 {
  text-align:center;
  text-wrap: none;
}


.Modellkontainer {
  display: grid;
  grid-template-columns: auto 200px 700px auto;
  grid-template-rows: auto;
  grid-template-areas: ". sidebar mainbar . ";
  border: 0px solid black;
}

.sidebar {
  grid-area: sidebar;
  padding:10px;
}

.mainbar {
  grid-area: sidebar;
  text-align: center;
  padding:10px;
}

.submenubutton {
  width:180px;
  margin:4px;
}

.forsidecontainer {
  border: 1px solid black;
}

.forsidecelle {
  position: relative;
  display:inline-block;
  padding:15px;
  width:auto;
}